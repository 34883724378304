<template>
  <v-app>
    <v-app-bar
      app
      color="black"
      class="lighten-6"
      elevate-on-scroll
      height="80"
    >
      <router-link
        tag="a"
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <img
          src="./assets/logo-bitcoinpoint.svg"
          height="50"
          class="mr-2 d-inline-block"
        />
        <span
          class="amber--text text-h6 ml-4 font-weight-medium text-uppercase"
        >
          {{ $t("academy") }}
        </span>
      </router-link>

      <v-spacer></v-spacer>
      <v-sheet
        color="transparent"
        max-width="400"
        width="100%"
        v-if="$i18n.locale === 'en'"
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="400"
          :max-width="400"
          offset-y
          :nudge-top="0"
          flat
          content-class="elevation-1"
        >
          <template v-slot:activator="{ attrs }">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              hide-details
              flat
              dark
              outlined
              clearable
              full-width
              v-bind="attrs"
              :placeholder="$t('search')"
            >
            </v-text-field>
          </template>
          <v-card max-width="400" flat>
            <v-list v-if="items">
              <v-list-item
                :key="item.uid"
                v-for="item in items"
                link
                @click="gotoArticle(item.uid)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="subtitle-1 font-weight-bold"
                    v-text="item.data.title"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item._highlightResult.body.value"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-pagination
              v-if="totalPages > 1"
              v-model="page"
              :length="totalPages"
              :total-visible="5"
            ></v-pagination>
          </v-card>
        </v-menu>
      </v-sheet>
      <v-toolbar-items>
        <v-tooltip bottom v-if="$i18n.locale === 'en'">
          <template v-slot:activator="{ on }">
            <v-btn text @click="changeLanguage" v-on="on">
              <img src="./assets/languages-icon.svg" width="40" />
            </v-btn>
          </template>
          <span>{{ $t("change_language") }}</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-app-bar>
    <router-view :topics="topics"></router-view>
    <v-footer class="black">
      <v-container>
        <v-row>
          <v-col cols="12" md="8" class="pa-5">
            <h4 class="text-h6 white--text mb-5">
              {{ $t("browse_by_topic") }}
            </h4>
            <v-btn
              v-for="item in topics"
              :key="item.slug"
              text
              class="mr-3 mb-3 primary white--text"
              :to="topicsPath + item.slug"
            >
              {{ item.name }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import algoliasearch from "algoliasearch/lite";

const client = algoliasearch("NY9RARGBTT", "dddff538c9beaf49d4c4cc4b7913b84a");
const index = client.initIndex("academy");

import { getTopicsPath, getArticlesPath } from "./router/index";

export default {
  name: "App",
  data: () => ({
    topicsPath: getTopicsPath(),
    articlesPath: getArticlesPath(),
    isLoading: false,
    topics: [],
    items: null,
    search: null,
    menu: false,
    page: 1,
    perPage: null,
    totalPages: null,
    totalResults: null,
  }),
  watch: {
    "$i18n.locale"() {
      this.getTopics();
    },
    page() {
      this.searchRequest();
    },
    search(val) {
      this.page = 1;
      if (val && val.length >= 3) {
        this.searchRequest();
      }
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale === "es" ? "es-es" : "en-us";
    },
  },
  methods: {
    searchRequest() {
      this.isLoading = true;
      this.menu = true;
      index
        .search(this.search, {
          page: this.page - 1,
        })
        .then(({ hits, nbPages, nbHits, hitsPerPage }) => {
          this.items = hits;
          this.totalPages = nbPages;
          this.perPage = hitsPerPage;
          this.totalResults = nbHits;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    gotoArticle(slug) {
      if (slug) {
        this.search = null;
        this.menu = false;
        this.$router.push(this.articlesPath + slug);
      }
    },
    changeLanguage() {
      if (this.$i18n.locale === "es") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "es";
      }
    },
    async getTopics() {
      const document = await this.$prismic.client.getSingle("categories", {
        lang: this.locale,
      });
      try {
        this.topics = document.data.categories;
      } catch (e) {
        this.topics = [];
      }
    },
  },
  created() {
    this.getTopics();
  },
};
</script>

<style>
body {
  font-family: "Montserrat";
}
li > * {
  vertical-align: text-top;
}
.v-application a {
  color: #1565c0 !important;
}
</style>