<template>
  <v-main class="grey lighten-5">
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container
      class="pt-0 pb-16"
      v-if="!isLoading && fields"
      sticky-container
    >
      <v-breadcrumbs :items="items" divider="/" class="px-2"></v-breadcrumbs>
      <v-row>
        <v-col cols="12" md="8" class="pa-5" style="font-size: 18px">
          <div class="mb-4">
            <v-btn
              v-for="tag in document.tags"
              :key="tag"
              small
              rounded
              text
              class="mr-3 mb-3 black white--text"
              :to="topicsPath + tag"
            >
              {{ tag }}
            </v-btn>
          </div>

          <h1
            id="h1"
            class="text-h4 text-md-h3 black--text d-inline-block mb-8"
          >
            {{ fields.title }}
          </h1>
          <div class="mb-10">
            <LevelChip :level="fields.level" small />
            <span
              class="ml-4 body-2 grey--text d-inline-block"
              v-if="fields.reading_time"
            >
              <v-icon small>mdi-clock-outline</v-icon>
              {{ fields.reading_time }} mins read
            </span>
          </div>

          <div class="mb-10" v-if="fields.image && fields.image.url">
            <v-img :src="fields.image.url" />
          </div>
          <prismic-rich-text class="mb-16 article-body" :field="fields.body" />
        </v-col>
        <v-col cols="12" md="4" v-if="!$vuetify.breakpoint.mobile">
          <v-card
            v-sticky
            sticky-side="both"
            sticky-offset="{top: 110, bottom: 30}"
            :sticky-z-index="1"
            flat
            outline
            class="app-link py-4 px-8 grey lighten-3 rounded-xl"
            v-if="sections.length"
          >
            <div class="my-4">
              <a
                class="text-decoration-none"
                :href="'#'"
                v-scroll-to="{ el: '#h1', offset: -180 }"
              >
                {{ fields.title }}
              </a>
            </div>
            <div
              class="my-4"
              :key="section"
              v-for="(section, index) in sections"
            >
              <a
                class="text-decoration-none"
                :href="'#'"
                v-scroll-to="{ el: '#h2-' + (index + 1), offset: -100 }"
                >{{ section }}</a
              >
            </div>
          </v-card>
        </v-col>
      </v-row>

      <template v-if="relations && relations.results_size >= 1">
        <h4 class="text-h5 primary--text mb-8 font-weight-bold">
          {{ $t("learn_more") }}
        </h4>

        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="4"
            :key="item.uid"
            v-for="item in relations.results"
          >
            <ArticleItem :item="item" />
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-main>
</template>
<script>
import LevelChip from "../components/LevelChip";
import ArticleItem from "../components/ArticleItem";
import { getTopicsPath, getArticlesPath } from "../router/index";

export default {
  props: {
    topics: {
      type: Array,
    },
  },
  data: () => ({
    topicsPath: getTopicsPath(),
    articlesPath: getArticlesPath(),
    isLoading: true,
    document: null,
    sections: null,
    relations: null,
  }),
  components: { LevelChip, ArticleItem },
  watch: {
    "$i18n.locale"() {
      this.loadContent();
    },
    "$route.params.uid"() {
      this.loadContent();
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale === "es" ? "es-es" : "en-us";
    },
    fields() {
      return this.document
        ? this.document.data
        : {
            title: [],
            body: [],
            image: null,
          };
    },
    topic() {
      if (!this.fields) {
        return {};
      }
      return this.topics.find((c) => c.slug == this.document.tags[0]);
    },
    items() {
      return [
        {
          text: this.$i18n.t("learn"),
          to: "/",
        },
        {
          text: this.$i18n.t("articles"),
          exact: true,
          disabled: false,
          to: this.articlesPath,
        },
        {
          text: this.fields.title,
          exact: true,
          disabled: true,
        },
      ];
    },
  },
  methods: {
    setTitle() {
      this.$title = this.fields.title;
    },
    getSections() {
      this.sections = this.fields.body
        .filter((i) => i.type == "heading2")
        .map((i) => i.text);

      setTimeout(() => {
        const els = this.$el.querySelectorAll("h2");
        els.forEach((h2, index) => {
          h2.id = "h2-" + (index + 1);
        });
      }, 500);
    },
    async loadContent() {
      this.isLoading = true;
      await this.getContent(this.$route.params.uid);
      this.isLoading = false;
      this.setTitle();
    },
    async getContent(uid) {
      const document = await this.$prismic.client.query(
        this.$prismic.Predicates.at("my.articles.uid", uid),
        { lang: this.locale }
      );

      this.document = document.results[0];

      if (!this.document) {
        return;
      }

      this.relations = await this.$prismic.client.query(
        this.$prismic.Predicates.at(
          "my.articles.relations.relation",
          this.document.id
        ),
        { lang: this.locale }
      );

      this.getSections();
    },
  },
  created() {
    this.loadContent();
  },
  mounted() {},
};
</script>
<style>
img {
  max-width: 100%;
}
h1 {
  margin-bottom: 30px;
}
h2 {
  margin-top: 50px;
  margin-bottom: 20px;
}
h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}
li {
  margin-bottom: 20px;
}
li > * {
  vertical-align: unset;
}
</style>