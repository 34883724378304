import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
Vue.config.productionTip = false;

Array.prototype.sortBy = function(p) {
  return this.slice(0).sort(function(a, b) {
    return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
  });
};

import PrismicVue from '@prismicio/vue';
import linkResolver from './link-resolver'; // Update this path if necessary

const accessToken =
  'MC5ZNXBDdFJjQUFDa0FoQlh5.Gihn77-977-977-9We-_vWbvv70477-977-9Qe-_vXrvv71FPRhWL--_ve-_vXHvv73vv716UGzvv71s'; // Leave empty if your repo is public
const endpoint = 'https://bitcoinpoint.cdn.prismic.io/api/v2'; // Use your repo name

// Register plugin
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver,
});

import VuePageTitle from 'vue-page-title';
Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  // suffix: '- My App '
});

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

import Sticky from 'vue-sticky-directive';
Vue.use(Sticky);

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

import router from './router';
import i18n from './i18n';

window.vm = new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
