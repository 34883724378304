<template>
  <v-chip
    :small="small"
    :color="levelColor"
    class="text--disabled font-weight-bold"
  >
    {{ $t(level ? level : "Beginner") }}
  </v-chip>
</template>

<script>
export default {
  props: { level: String, small: Boolean },
  computed: {
    levelColor() {
      if (this.level == "Avanced") {
        return "purple lighten-5";
      } else if (this.level == "Intermediate") {
        return "blue accent-1";
      }
      return "green accent-1";
    },
  },
};
</script>
