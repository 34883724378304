<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :elevation="hover ? 9 : 0"
      class="rounded-xl"
      outlined
      :to="articlesPath + item.uid"
    >
      <router-link :to="articlesPath + item.uid">
        <v-img
          :src="item.data.image.url"
          v-if="item.data.image && item.data.image.url"
          class="mb-4 rounded-xl"
        />
        <v-img src="../assets/placeholder.png" class="mb-4 rounded-xl" v-else />
      </router-link>
      <v-card-text>
        <h3 class="headline black--text mb-8" style="height: 60px">
          {{ item.data.title }}
        </h3>
        <LevelChip :level="item.data.level" small />
        <span
          class="ml-4 grey--text d-inline-block"
          v-if="item.data.reading_time"
        >
          <v-icon small>mdi-clock-outline</v-icon>
          {{ item.data.reading_time }}m
        </span>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import LevelChip from "../components/LevelChip";
import { getTopicsPath, getArticlesPath } from "../router/index";

export default {
  name: "ArticleItem",
  props: { item: Object },
  data: () => ({
    topicsPath: getTopicsPath(),
    articlesPath: getArticlesPath(),
  }),
  components: { LevelChip },
  computed: {
    levelColor() {
      if (this.item.data.level == "Avanced") {
        return "purple lighten-5";
      } else if (this.item.data.level == "Intermediate") {
        return "blue accent-1";
      }
      return "green accent-1";
    },
  },
};
</script>

<style>
</style>