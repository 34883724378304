import Vue from 'vue';
import Router from 'vue-router';

// The meta data for your routes
const meta = require('./meta.json');

// Function to create routes
// Is default lazy but can be changed
function route(path, view) {
  return {
    path: path,
    meta: meta[path],
    component: (resolve) => import(`../pages/${view}.vue`).then(resolve),
  };
}

Vue.use(Router);

import { getSiteLocale } from '../i18n';

export function getTopicsPath() {
  return getSiteLocale() == 'es' ? '/temas/' : '/topics/';
}

export function getArticlesPath() {
  return getSiteLocale() == 'es' ? '/articulos/' : '/articles/';
}

const router = new Router({
  base: __dirname,
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    route('/', 'home'),
    route('/topics/:tag', 'topic'),
    route('/articles/:uid', 'article'),
    route('/articles(/)?', 'articles'),

    route('/temas/:tag', 'topic'),
    route('/articulos/:uid', 'article'),
    route('/articulos(/)?', 'articles'),

    // Global redirect for 404
    { path: '*', redirect: '/' },
  ],
});

// Send a pageview to Google Analytics
router.beforeEach((to, from, next) => {
  if (typeof ga !== 'undefined') {
    //ga('set', 'page', to.path)
    //ga('send', 'pageview')
  }
  next();
});

export default router;
