<template>
  <v-main class="grey lighten-5" transition="fade-transition">
    <div class="hero text-center">
      <v-sheet
        class="transparent"
        max-width="1000"
        width="98%"
        style="margin: 0 auto"
      >
        <div class="py-12 text-center black--text">
          <div>
            <img src="../assets/bitacademy.png" width="200" />
          </div>
          <div class="mx-14">
            <h1 class="text-h4 mb-4 font-weight-bold">
              {{ $t("home_title") }}
            </h1>
            <div class="title">
              {{ $t("home_subtitle") }}
            </div>
          </div>
        </div>
      </v-sheet>
    </div>
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container class="pt-8 pb-16" v-if="!isLoading">
      <v-row>
        <v-col cols="12" md="12" class="pa-5">
          <v-row>
            <v-col>
              <router-link
                :to="'/articles'"
                class="
                  text-h5 text-md-h4 text-decoration-none
                  primary--text
                  mr-3
                  d-inline-block
                  my-0
                "
              >
                {{ $t("latest_articles") }}
              </router-link>
            </v-col>
            <v-col class="text-right">
              <v-btn
                :to="articlesPath"
                color="black"
                large
                rounded
                depressed
                class="white--text text-decoration-none"
              >
                {{ $t("see_more") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <template v-for="(item, index) in latestArticles">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                :key="item.uid"
                v-if="index < 9"
              >
                <ArticleItem :item="item" />
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="pa-5">
          <v-row>
            <v-col>
              <router-link
                :to="topicsPath + 'beginning'"
                class="
                  text-h5 text-md-h4 text-decoration-none
                  primary--text
                  mr-3
                  d-inline-block
                  my-0
                "
              >
                {{ $t("beginning") }}
              </router-link>
            </v-col>
            <v-col class="text-right">
              <v-btn
                :to="topicsPath + 'beginning'"
                color="black"
                large
                rounded
                depressed
                class="white--text text-decoration-none"
              >
                {{ $t("see_more") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <template v-for="(item, index) in articles">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                :key="item.uid"
                v-if="index < 9"
              >
                <ArticleItem :item="item" />
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import ArticleItem from "../components/ArticleItem";
import { getTopicsPath, getArticlesPath } from "../router/index";

export default {
  name: "Home",
  title: window.vm.$i18n.t("learn"),
  props: {
    topics: {
      type: Array,
    },
  },
  data: () => ({
    isLoading: true,
    topicsPath: getTopicsPath(),
    articlesPath: getArticlesPath(),
    articles: [],
  }),
  components: { ArticleItem },
  watch: {
    "$i18n.locale"() {
      this.loadContent();
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale === "es" ? "es-es" : "en-us";
    },
    articlesOrdered() {
      return this.articles ? this.articles.results.sortBy("position") : [];
    },
  },
  methods: {
    getTopicArticles(tag) {
      const articles = this.articlesOrdered.filter(
        (item) => item.tags[0] === tag
      );
      return articles.sortBy("position");
    },
    async loadContent() {
      this.isLoading = true;
      await this.getLatestArticles();
      await this.getArticles();
      this.isLoading = false;
    },
    async getLatestArticles() {
      const document = await this.$prismic.client.query(
        [this.$prismic.Predicates.at("document.type", "articles")],
        { lang: this.locale, pageSize: 3 }
      );
      this.latestArticles = document.results;
    },
    async getArticles() {
      const document = await this.$prismic.client.query(
        [
          this.$prismic.Predicates.at("document.type", "articles"),
          this.$prismic.Predicates.at("document.tags", [
            "beginning",
            "bitcoin",
          ]),
        ],
        { lang: this.locale, pageSize: 3 }
      );
      this.articles = document.results;
    },
  },
  mounted() {
    this.loadContent();
  },
  beforeRouteUpdate(to, from, next) {
    this.loadContent();
    next();
  },
};
</script>

<style scoped>
.hero {
  background: radial-gradient(
    circle,
    rgba(255, 178, 77, 0.8) 0%,
    rgba(255, 100, 25, 1) 100%
  );
}
</style>